import { TrackerItem } from "../models/ListItems";
import { sp } from "@pnp/sp/presets/all";
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import appInsights from "../ApplicationInsights";

export const prefix_RecordId = "Record Id";
const prefix_ProjectCode = "Project Code";
const prefix_None = "No Prefix";
const name_TrackerList = "Record Id Tracker";

export async function GetTracker(documentType: string): Promise<TrackerItem> {
  let trackerItem = {} as TrackerItem;
  if (documentType !== "" && documentType !== null) {
    try {
      const items = (await sp.web.lists
        .getByTitle(name_TrackerList)
        .items.get()) as TrackerItem[];
      trackerItem = items.filter(function (item) {
        return item.Title === documentType;
      })[0];
    } catch (e) {
      appInsights.trackException({
        error: new Error(
          `Failed to get tracker for document type ${documentType} : ${e}. \nThis site may be missing a document tracker list.`
        ),
        severityLevel: SeverityLevel.Error,
      });
    }
    if (trackerItem === undefined) {
      alert(
        `${documentType} was not found in this site's tracker list. No record ID will be generated.`
      );
      return {} as TrackerItem;
    }
    if (trackerItem !== ({} as TrackerItem) && trackerItem !== null) {
      if (trackerItem.Prefix === null || trackerItem.Prefix === "") {
        trackerItem.Prefix = prefix_None;
      }
    }
  }
  return trackerItem;
}

//Calculate the next ID
export function GetNextId(project: string, trackerItem: TrackerItem): string {
  let nextId = "";

  switch (trackerItem.Prefix) {
    //PROJECTCODE-ABV-ID
    case prefix_RecordId:
      if (trackerItem.LastUsedId != null) {
        nextId = (trackerItem.LastUsedId + 1).toString();
      } else {
        nextId = "001";
      }
      //Pad Id with 0's
      if (nextId.length < 3) {
        const zeros = 3 - nextId.length;
        nextId = Array(zeros + 1).join("0") + nextId;
      }
      return `${project}-${trackerItem.Abbreviation}-${nextId}`;

    //PROJECTCODE
    case prefix_ProjectCode:
      return `${project}`;

    //No prefix
    case prefix_None:
      return "";
    default:
      return "";
  }
}

// Update the last used id in the tracker
export async function UpdateLastUsedId(
  nextID: string,
  trackerId: number
): Promise<void> {
  try {
    const lastId = Number(nextID.substring(nextID.length - 3)); // Get last 3 digits of id

    const updateData = {
      LastUsedId: lastId,
    };

    const item = await sp.web.lists
      .getByTitle(name_TrackerList)
      .items.getById(trackerId);
    await item.update(updateData);

    // confirm update has occurred
    const checkItem = (await sp.web.lists
      .getByTitle(name_TrackerList)
      .items.getById(trackerId)
      .get()) as TrackerItem;

    if (lastId !== checkItem.LastUsedId) {
      alert(
        "Last Used Id was not successfully updated in Site Content -> Record Id Tracker."
      );
      console.log(
        "Last Used Id was not successfully updated in Site Content -> Record Id Tracker."
      );
      appInsights.trackException({
        error: new Error(`Error updating last used id in check item.`),
        severityLevel: SeverityLevel.Error,
      });
    }
  } catch (e) {
    appInsights.trackException({
      error: new Error(`Error updating last used id : ${e}`),
      severityLevel: SeverityLevel.Error,
    });
    throw e;
  }
}
