import { useEffect, useRef, useState } from "react";
import { getProfileInfo, UserProfileInfo } from "../lib/UserInfo";

let userInfoCache: UserProfileInfo | null = null;

export function clearUserInfoCache() {
  userInfoCache = null;
}

export function useUserInfo(): {
  userProfileInfo: UserProfileInfo;
  isUserInfoLoading: boolean;
  userError: Error | null;
} {
  const isMounted = useRef(false);
  const [userProfileInfo, setUserProfileInfo] = useState<UserProfileInfo>(
    {} as UserProfileInfo
  );
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(true);
  const [userError, setError] = useState<Error | null>(null);

  const init = async () => {
    if (userInfoCache) {
      setUserProfileInfo(userInfoCache);
      setIsUserInfoLoading(false);
      setError(null);
      return;
    }

    try {
      const response = await getProfileInfo();

      if (!isMounted.current) {
        return;
      }

      userInfoCache = response;

      setUserProfileInfo(response);
      setIsUserInfoLoading(false);
      setError(null);
    } catch (e: any) {
      setUserProfileInfo({} as UserProfileInfo);
      setError(e);
      setIsUserInfoLoading(false);
    }
  };

  useEffect(function () {
    isMounted.current = true;
    init();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    userProfileInfo,
    isUserInfoLoading,
    userError,
  };
}
