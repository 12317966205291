import React, { FunctionComponent } from "react";
import { Text, Link, Panel, Stack } from "@fluentui/react";
import { navigate } from "hookrouter";
import logo from "../../assets/img/logo.svg";
import { useAuth } from "../AuthProvider";
import {
  IPersonaSharedProps,
  Persona,
  PersonaSize,
} from "@fluentui/react/lib/Persona";
import HeaderNav from "./HeaderNav";
import "./index.css";

const headerStyle: React.CSSProperties = {
  background: "#284C5C",
  padding: "3px 5px",
  minHeight: "54px",
};

const HeaderBar: FunctionComponent = () => {
  const { profile, logout } = useAuth();
  const [isUserInfoOpen, setIsUserInfoOpen] = React.useState(false);
  const [isHelpInfoOpen, setIsHelpInfoOpen] = React.useState(false);

  const openUserInfoPanel = React.useCallback(() => {
    setIsUserInfoOpen(true);
  }, []);

  const openHelpInfoPanel = React.useCallback(() => {
    setIsHelpInfoOpen(true);
  }, []);

  const persona: IPersonaSharedProps = {
    imageInitials: profile?.initals,
    text: profile?.fullName,
    secondaryText: profile?.position,
    imageUrl: profile?.avatarUrl ?? undefined,
    imageAlt: profile?.fullName,
  };

  return (
    <div>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        style={headerStyle}
      >
        <Stack.Item>
          <Stack horizontal verticalAlign="center">
            <Link className="headerBar-logo-link" onClick={() => navigate("/")}>
              <img src={logo} alt="logo" className="headerBar-logo" />
            </Link>
            <div className="headerBar-separator"></div>
            <Text variant="medium" className="headerBar-title">
              Document Generation Tool
            </Text>
          </Stack>
        </Stack.Item>

        {profile ? (
          <Stack.Item>
            <HeaderNav
              personaInfo={persona}
              openUserInfoPanel={openUserInfoPanel}
              openHelpPanel={openHelpInfoPanel}
            ></HeaderNav>
          </Stack.Item>
        ) : null}
      </Stack>
      <Panel
        headerText="My Account"
        isOpen={isUserInfoOpen}
        onDismiss={() => setIsUserInfoOpen(false)}
        closeButtonAriaLabel="Close"
        isLightDismiss
      >
        <Persona
          {...persona}
          size={PersonaSize.size72}
          styles={{ root: { marginTop: 20 } }}
          onRenderTertiaryText={() => (
            <div>
              {" "}
              <Link
                onClick={() => {
                  logout();
                  setIsUserInfoOpen(false);
                }}
                styles={{ root: { marginTop: "10px" } }}
              >
                Sign out
              </Link>
            </div>
          )}
        />
      </Panel>
      <Panel
        headerText="Help"
        isOpen={isHelpInfoOpen}
        onDismiss={() => setIsHelpInfoOpen(false)}
        closeButtonAriaLabel="Close"
        isLightDismiss
      >
        <div style={{ marginTop: 15 }}>
          <p>
            Visit the IT service desk portal to create or track a support ticket
            and search documentation.
          </p>
          <p>
            <Link
              href="https://jira.gotosage.com/servicedesk/customer/portal/"
              target="_blank"
            >
              Service Desk Portal
            </Link>
          </p>
          <h4>Helpful Links</h4>
          <ul>
            <li>
              <Link
                href="https://gotosage.atlassian.net/wiki/spaces/marketing/pages/1310982277/Document+Generation+-+User+Guide"
                target="_blank"
              >
                User Guide
              </Link>
            </li>
          </ul>
        </div>
      </Panel>
    </div>
  );
};
export default HeaderBar;
