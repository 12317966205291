import { useCallback, useEffect, useRef, useState } from "react";
import { RFIApi } from "./RFIApi";
import { RFI, RFISearchResult } from "../../../models/RFI";
import { RFI_LIMIT } from "../../../constants/global";

export function useRFIList(projectCode: string | null): {
  rfiListResult: RFISearchResult;
  isRFIListLoading: boolean;
  rfiError: Error | null;
} {
  const isMounted = useRef(false);
  const [rfiListResult, setRFIListResult] = useState<RFISearchResult>(
    {} as RFISearchResult
  );
  const [isRFIListLoading, setIsRFIListLoading] = useState(true);
  const [rfiError, setError] = useState<Error | null>(null);

  const init = useCallback(async () => {
    try {
      if (projectCode === null || projectCode === "") {
        setRFIListResult({ results: [] as RFI[] } as RFISearchResult);
        setIsRFIListLoading(false);
      } else {
        /**
         * The page is hardcoded to 1, as the 1st page contains the first 100 RFI's (dependant on RFI_LIMIT), the page is only incremented later if more than 100 RFI's exist for a
         * given project.
         */

        const api = new RFIApi();
        let RFIReturnObject: RFISearchResult = {
          results: new Array<RFI>(),
          showing: 0,
          total: 0,
          nextPage: 0,
        };

        let page = 1;
        let response = await api.getRFIList(
          projectCode.replace("UAT-", ""),
          page,
          RFI_LIMIT
        );

        if (response.ok !== true) {
          throw new Error(`RFI list error: ${response.statusText}`);
        }

        RFIReturnObject = response.body;

        /**
         *
         * A while loop is created to examine if there are the maximum RFI_LIMIT of results returned as well as the nextPage value returned being greater than the page provided
         * (only incremented if total > RFI_LIMIT). If there is more than 100 (or what RFI_LIMIT is set to) entries (and multiple pages), the function enters the while
         * loop and increments the page followed by making another call to the API looking at the incremented page. If the response is okay, the resulting RFI's are
         * concatenated once again.
         *
         * Once all pages have had their RFI's added to the return object, the while loop will break indicating that all RFI's necessary for the document generation are prepared.
         */

        while (
          response.body.results.length === RFI_LIMIT &&
          response.body.nextPage > page
        ) {
          page = response.body.nextPage;
          response = await api.getRFIList(
            projectCode.replace("UAT-", ""),
            page,
            RFI_LIMIT
          );
          if (response.ok !== true) {
            console.log(response);
            throw new Error(`RFI list error: ${response.statusText}`);
          }
          RFIReturnObject.results = RFIReturnObject.results.concat(
            response.body.results
          );
        }

        if (!isMounted.current) {
          return;
        }

        setRFIListResult(RFIReturnObject);
        setIsRFIListLoading(false);
      }
    } catch (e: any) {
      setRFIListResult({
        results: {},
      } as RFISearchResult);
      setError(e);
      setIsRFIListLoading(false);
    }
  }, [projectCode]);

  useEffect(
    function () {
      isMounted.current = true;
      init();
      return () => {
        isMounted.current = false;
      };
    },
    [init]
  );

  return {
    rfiListResult,
    isRFIListLoading,
    rfiError,
  };
}
