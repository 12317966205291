import {
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { navigate } from "hookrouter";
import { FunctionComponent, useEffect } from "react";
import { useAuth } from "../components/AuthProvider";
import ElevatedView from "../components/ElevatedView/index";
const Login: FunctionComponent = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      // Get return path
      const query = new URLSearchParams(window.location.search);
      const returnPath = query.get("returnTo");
      navigate(returnPath ?? "/", true);
    }
  }, [auth.isAuthenticated]);

  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      verticalFill
      styles={{ root: { alignSelf: "center", margin: "0 auto" } }}
    >
      <ElevatedView
        depth={16}
        style={{
          padding: 55,
          width: "400px",
          maxWidth: "90%",
          textAlign: "center",
        }}
      >
        {auth.isChecking ? (
          <Spinner size={SpinnerSize.medium} />
        ) : (
          <>
            <span>
              <b>Welcome</b>
            </span>

            <p>To continue please login below.</p>

            <Separator />

            <PrimaryButton
              styles={{ root: { marginTop: 20 } }}
              iconProps={{ iconName: "OfficeLogo" }}
              onClick={() => auth.login()}
            >
              Log in with Work Email
            </PrimaryButton>
          </>
        )}
      </ElevatedView>
    </Stack>
  );
};

export default Login;
