import { FunctionComponent } from "react";
import { useAuth } from "../components/AuthProvider/index";
import { Text, PrimaryButton, DefaultButton, Stack } from "@fluentui/react";
import { navigate } from "hookrouter";
import ElevatedView from "../components/ElevatedView/index";

const LogoutPage: FunctionComponent = () => {
  const auth = useAuth();

  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      verticalFill
      styles={{ root: { alignSelf: "center", margin: "0 auto" } }}
    >
      <ElevatedView
        depth={16}
        style={{
          padding: 55,
          width: "400px",
          maxWidth: "90%",
          textAlign: "center",
        }}
      >
        <Text variant="xxLarge">Sign out</Text>

        {auth.isAuthenticated ? (
          <>
            <p>Are you sure you want to sign out?</p>

            <Stack horizontal gap={15} horizontalAlign="center">
              <PrimaryButton onClick={() => auth.logout()}>Yes</PrimaryButton>
              <DefaultButton onClick={() => navigate("/")}>No</DefaultButton>
            </Stack>
          </>
        ) : (
          <>
            <p>You have been signed out.</p>

            <Stack horizontal gap={15} horizontalAlign="center">
              <PrimaryButton onClick={() => navigate("/login")}>
                Login
              </PrimaryButton>
            </Stack>
          </>
        )}
      </ElevatedView>
    </Stack>
  );
};

export default LogoutPage;
