// Date's sent from PW API are in date time format,
// recreating it here, strips the time info and allows
// us to use functions such as GetDay()
export function getDisplayDate(date: string | number | Date): string {
  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    return "N/A";
  }

  return (
    newDate.getDate().toString().padStart(2, "0") +
    "-" +
    (newDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    newDate.getFullYear()
  );
}

export function getDisplayTime(date: string | number | Date): string {
  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    return "N/A";
  }
  let hours = newDate.getHours();

  const minutes = newDate.getMinutes();

  return (
    hours.toString().padStart(2, "0") +
    "-" +
    minutes.toString().padStart(2, "0")
  );
}
