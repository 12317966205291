import React, { FunctionComponent } from "react";
import { Stack } from "office-ui-fabric-react";
import { linkStyle, titleStyle } from "../../styles";
import "./index.css";
import config from "../../Config";

type PageContentProps = {
  title: string;
  children: React.ReactNode;
  goToTitle?: string;
  goToUrl?: string;
};

const PageContent: FunctionComponent<PageContentProps> = ({
  title,
  children,
  goToTitle,
  goToUrl,
}) => {
  console.log("config", config.environment);
  let titleSection = null;
  if (goToTitle && goToUrl) {
    titleSection = (
      <div style={titleStyle}>
        {config.environment === "UAT" ? (
          <span style={{ font: "Segoe UI", fontSize: "28px", color: "red" }}>
            THIS IS A TEST ENVIRONMENT
          </span>
        ) : (
          ""
        )}
        <span>{title}</span>
        <a style={linkStyle} href={goToUrl}>
          {goToTitle}
        </a>
      </div>
    );
  } else {
    titleSection = (
      <div style={{ display: "contents" }}>
        {config.environment === "UAT" ? (
          <span style={{ font: "Segoe UI", fontSize: "28px", color: "red" }}>
            THIS IS A TEST ENVIRONMENT
          </span>
        ) : (
          ""
        )}
        <span style={{ font: "Segoe UI", fontSize: "28px" }}>{title}</span>
      </div>
    );
  }

  return (
    <Stack
      className="pageContent-wrapper"
      horizontalAlign="start"
      verticalAlign="center"
      styles={{ root: { alignSelf: "start" } }}
    >
      <Stack
        className="pageContent"
        tokens={{
          childrenGap: 10,
        }}
      >
        {titleSection}
        {children}
      </Stack>
    </Stack>
  );
};
export default PageContent;
