import React, { FunctionComponent } from "react";

export type ElevatedViewProps = {
  depth?: 0 | 4 | 8 | 16 | 64;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const ElevatedView: FunctionComponent<ElevatedViewProps> = ({
  depth,
  style,
  children,
}) => <div style={{ ...style, boxShadow: depths[depth ?? 0] }}>{children}</div>;

const depths = {
  0: "0 0 0 0 transparent",
  4: "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
  8: "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
  16: "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
  64: "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
};

export default ElevatedView;
