import * as Msal from "@azure/msal-browser";

export type GlobalConfiguration = {
  api: {
    sharepointUrl: string;
    docAlloyApiUrl: string;
    webApiUrl: string;
  };
  msal: Msal.Configuration;
  authScopes: {
    graphApi: string[];
    centralApi: string[];
    docAlloyApi: string[];
  };
  functionKeys: {
    contentUrl: string;
    templateUrl: string;
    generateUrl: string;
    getFileUrl: string;
  };
  environment: string;
};

export const config: GlobalConfiguration = require("./local.settings.json");

export default config;
