import * as Msal from "@azure/msal-browser";
import config from "../Config";
import { clearUserInfoCache } from "../hooks/useUserInfo";
import { isIEOrEdge } from "./BrowserInfo";

export const msal = new Msal.PublicClientApplication({
  ...config.msal,
  cache: {
    ...config.msal.cache,
    storeAuthStateInCookie: isIEOrEdge,
  },
});
const scopes = config.authScopes;

let currentAccount: Msal.AccountInfo | null = null;
let tokens: Record<keyof typeof scopes, Msal.AuthenticationResult | null> = {
  graphApi: null,
  centralApi: null,
  docAlloyApi: null,
};

export const login = async () => {
  await msal.loginRedirect({
    scopes: ["openid", "profile", "User.Read"],
    extraScopesToConsent: [
      ...scopes.graphApi,
      ...scopes.centralApi,
      ...scopes.docAlloyApi,
    ],
  });
};

export const logout = () => {
  clearUserInfoCache();
  msal.logoutRedirect();
};

export const aquireToken = async (key: keyof typeof scopes): Promise<void> => {
  const account = getAccount();

  if (!account) {
    //console.log("Cannot acquireToken when not logged in");
    return;
  }

  try {
    //console.log("Attempting acquireTokenSilent(" + key + ")...");

    tokens[key] = await msal.acquireTokenSilent({
      account: account,
      scopes: scopes[key],
    });

    //console.log("Success acquireTokenSilent(" + key + ")...");
  } catch (error: any) {
    //console.log("Failed to acquireTokenSilent", [error.name, error]);

    if (error instanceof Msal.InteractionRequiredAuthError) {
      //console.log("Attempting acquireTokenRedirect(" + key + ")...");

      await msal.acquireTokenRedirect({ scopes: scopes[key] });
    } else {
      throw error;
    }
  }

  return;
};

export const getAccount = () => {
  if (currentAccount) return currentAccount;

  const currentAccounts = msal.getAllAccounts();

  if (currentAccounts === null || !currentAccounts.length) {
    return null;
  } else {
    currentAccount = currentAccounts.find((acc) => !!acc.homeAccountId) ?? null;
    return currentAccount;
  }
};

export const getAccessToken = async (
  key: keyof typeof scopes
): Promise<string | null> => {
  await aquireToken(key);
  return tokens[key]?.accessToken ?? null;
};

export const checkForRedirectToken = async () => {
  if (!redirectPromise) {
    //console.log("No handleRedirectPromise found");
    return;
  }

  //console.log("Checking for redirect token...");

  try {
    const tokenResponse = await redirectPromise;

    if (tokenResponse !== null) {
      //console.log("Redirect token found", tokenResponse);

      switch (tokenResponse.scopes) {
        case scopes.docAlloyApi:
          tokens.docAlloyApi = tokenResponse;
          break;
        case scopes.centralApi:
          tokens.centralApi = tokenResponse;
          break;
        case scopes.graphApi:
          tokens.graphApi = tokenResponse;
          break;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const redirectPromise = msal.handleRedirectPromise();
