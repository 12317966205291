import { sp } from "@pnp/sp";
import React, {
  createContext,
  useContext,
  useState,
  FunctionComponent,
  useEffect,
} from "react";
import config from "../../Config";
import * as auth from "../../lib/Auth";
import * as Storage from "../../lib/Storage";
import { UserProfileInfo, getProfileInfo } from "../../lib/UserInfo";
import { PnPFetchClient } from "../../pnp/PnPFetchClient";
import { msal } from "../../lib/Auth";

export type AuthProviderProps = {
  children: React.ReactNode;
};

function initPnPjs(site: string): void {
  const fetchClientFactory = () => {
    return new PnPFetchClient(msal);
  };
  sp.setup({
    sp: {
      fetchClientFactory,
      baseUrl: `${config.api.sharepointUrl}/sites/${site}`,
    },
  });
}

export type AuthInfo = {
  isAuthenticated: boolean;
  isChecking: boolean;
  profile: UserProfileInfo | null;
  login: () => Promise<void>;
  logout: () => void;
};

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({
  children,
}) => {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const AuthContext = createContext<AuthInfo>({} as any);

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  if (window.__SITE_INFO__) {
    initPnPjs(window.__SITE_INFO__.site ?? "");
  }
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [profileInfo, setProfileInfo] = useState<UserProfileInfo | null>(null);

  const login = async () => {
    setIsChecking(true);
    await auth.login();
  };

  const logout = () => {
    auth.logout();
    //Storage.resetUserKey();
    setProfileInfo(null);
    setIsAuthenticated(false);
    setIsChecking(false);
  };

  const authHandler = async () => {
    setIsChecking(true);

    try {
      await auth.checkForRedirectToken();
      await auth.aquireToken("graphApi");
      await auth.aquireToken("centralApi");
      await auth.aquireToken("docAlloyApi");

      const account = auth.getAccount();

      if (!account) {
        throw new Error("Failed to acquire auth tokens");
      }

      Storage.setUserKey(auth.getAccount()?.homeAccountId);

      let newProfile = Storage.get<UserProfileInfo>(Storage.Keys.ProfileInfo);

      if (!newProfile) {
        newProfile = await getProfileInfo();

        if (newProfile)
          Storage.set<UserProfileInfo>(Storage.Keys.ProfileInfo, newProfile);
      }

      setProfileInfo(newProfile);
      setIsAuthenticated(true);
    } catch (e) {
      console.log("Failed to retrieve authentication tokens", e);
      setIsAuthenticated(false);
    } finally {
      setIsChecking(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      authHandler();
    }

    // Ignore dependancies warning below
    // We definately only want this to run on mount
    // eslint-disable-next-line
  }, []);

  return {
    isAuthenticated,
    isChecking,
    profile: profileInfo,
    login,
    logout,
  };
}
