import "./App.css";
import Home from "./screens/Home";
import HeaderBar from "./components/HeaderBar";
import NotFound from "./screens/NotFound";
import { useRoutes } from "hookrouter";
import React from "react";
import { AuthProvider } from "./components/AuthProvider";
import Login from "./screens/Login";
import Logout from "./screens/Logout";
import SiteInfo from "./models/SiteInfo";
import { loadTheme, createTheme, Stack } from "@fluentui/react";

const appTheme = createTheme({
  palette: {
    themePrimary: "#284c5c",
    themeLighterAlt: "#f3f7f8",
    themeLighter: "#d0dfe5",
    themeLight: "#abc3ce",
    themeTertiary: "#678d9d",
    themeSecondary: "#385f6f",
    themeDarkAlt: "#244453",
    themeDark: "#1e3a46",
    themeDarker: "#162b33",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

loadTheme(appTheme);

declare global {
  interface Window {
    __SITE_INFO__: SiteInfo;
    __PROJECT_CODE__: string;
    __BRAND__: string;
  }
}

export const routes = {
  "/": () => <Home />,
  "/login": () => <Login />,
  "/logout": () => <Logout />,
  "/404": () => <NotFound />,
};

const App: React.FunctionComponent = () => {
  // Set global site info
  const urlParams = new URLSearchParams(window.location.search);
  const siteUrl = urlParams.get("siteInfo") ?? "";
  if (siteUrl !== "") {
    window.__SITE_INFO__ = JSON.parse(siteUrl) as SiteInfo;
  }

  const projectCode = urlParams.get("site") ?? "";
  if (projectCode !== "") {
    window.__PROJECT_CODE__ = projectCode;
  }

  const brand = urlParams.get("brand") ?? "";
  if (brand !== "") {
    window.__BRAND__ = brand;
  }

  const routeResult = useRoutes(routes);

  return !routeResult ? (
    <NotFound />
  ) : (
    <Stack
      className="app"
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={{
        root: { width: "100%", height: "100vh", alignSelf: "stretch" },
      }}
    >
      <Stack.Item align="stretch">
        <HeaderBar />
      </Stack.Item>

      <Stack.Item
        grow={1}
        align="stretch"
        verticalFill
        styles={{
          root: { backgroundColor: "white", display: "flex" },
        }}
      >
        {routeResult}
      </Stack.Item>
    </Stack>
  );
};

const AppWrapper: React.FunctionComponent = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AppWrapper;
