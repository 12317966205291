/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import {
  ContentItem,
  IDetailsListItem,
  TemplateItem,
} from "../models/ListItems";
import {
  fileIcon,
  itemStatus,
  statusValues,
} from "../components/DetailsList/RenderColumns";
import {
  includesText,
  validateTemplate,
} from "./APIs/Documents/DocumentListHelpers";

export function useItemList(
  contents: ContentItem[],
  templates: TemplateItem[],
  brand: string,
  brandIndex: { [id: string]: string[] }
): {
  items: IDetailsListItem[];
  isItemsLoading: boolean;
  itemError: Error | null;
} {
  const isMounted = useRef(false);
  const [isItemsLoading, setIsItemsLoading] = useState(true);
  const [itemError, setItemError] = useState<Error | null>(null);
  const [items, setItems] = useState<IDetailsListItem[]>([]);

  const init = async () => {
    try {
      if (!isMounted.current) {
        return;
      }

      let typeIndex: string[] = [];
      let newItems: IDetailsListItem[] = [];

      contents.forEach((content) => {
        // load type index
        if (!typeIndex.includes(content.documentType)) {
          typeIndex.push(content.documentType);
        }
        // load content list
        newItems.push({
          key: content.id,
          name: content.name,
          fileName: content.fileName,
          type: content.documentType,
          icon: fileIcon(content.fileType).url,
          fileType: content.fileType,
          template: validateTemplate(brandIndex, brand, content.template),
          templateId: content.template,
          status: itemStatus(statusValues.None),
          documentId: content.documentId,
          date: content.date,
          folder: content.folder,
          version: content.version,
        });
      });

      setItems(newItems.filter((i) => includesText(i)) || newItems);
      setIsItemsLoading(false);
    } catch (e: any) {
      setItems([]);
      setItemError(e);
      setIsItemsLoading(false);
    }
  };

  useEffect(
    function () {
      isMounted.current = true;
      init();
      return () => {
        isMounted.current = false;
      };
    },
    [contents, templates, brand, brandIndex]
  );

  return {
    items,
    isItemsLoading,
    itemError,
  };
}
