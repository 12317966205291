import { getAccessToken } from "./Auth";
import { IObjectWithKey } from "@fluentui/react";
import {
  IDetailsListItem,
  TemplateItem,
  TrackerItem,
} from "../models/ListItems";
import { DownloadFile, UploadFile } from "./File";
import config from "../Config";
import "@pnp/sp/fields";
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";
import {
  GetNextId,
  GetTracker,
  UpdateLastUsedId,
  prefix_RecordId,
} from "./GenerateDocId";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import appInsights from "../ApplicationInsights";
import { sp } from "@pnp/sp";
import {
  itemStatus,
  statusValues,
} from "../components/DetailsList/RenderColumns";
import { IProjectGeneric } from "../models/Project";
import { Variation } from "../models/Variation";
import { RFI } from "../models/RFI";

export async function onCreateClick(
  selections: IObjectWithKey[],
  selectedBrand: string,
  templates: TemplateItem[],
  items: IDetailsListItem[],
  project: IProjectGeneric,
  rfi: RFI[],
  variation: Variation[],
  user: string
) {
  appInsights.trackEvent({
    name: "Create documents clicked",
    properties: {
      User: user,
      "Selected items": selections,
      Project: project,
    },
  });

  const siteInfo = window.__SITE_INFO__;
  for (const selection of selections) {
    // Update status
    let item = items.find((item) => item.key === selection.key);
    if (item) {
      item.status = itemStatus(statusValues.Processing);
    }

    let response = false;
    const dateString = GetDateString(item);
    let body = GetBodyJson(
      dateString,
      item,
      project,
      rfi,
      variation,
      selectedBrand
    );

    try {
      response = await CreateTemplate(
        selection,
        selectedBrand,
        templates,
        siteInfo?.site ?? "",
        body,
        user
      );
    } catch {
      body = `{ Document :  { "Id" : "${item?.documentId}", "Version": "${item?.version}", "Date": "${dateString}" }}`;
      response = await CreateTemplate(
        selection,
        selectedBrand,
        templates,
        siteInfo?.site ?? "",
        body,
        user
      );
    }

    item = items.find((item) => item.key === selection.key);
    if (response) {
      if (item) {
        item.status = itemStatus(statusValues.Successful);
      }
    } else {
      if (item) {
        item.status = itemStatus(statusValues.Unsuccessful);
      }
    }
    appInsights.trackEvent({
      name: "Document created",
      properties: {
        User: user,
        Document: item?.name,
        Project: project,
        Success: response,
      },
    });
  }

  if (siteInfo !== undefined) {
    // redirect back to sharepoint site
    // have to pass path as ID as well, as if they try and generate another doc it relies on the ID
    window.location.replace(
      `${config.api.sharepointUrl}${siteInfo?.pathToFolder ?? ""}?id=${
        siteInfo?.pathToFolder
      }`
    );
  }
}
export type RequestOptions = {
  method: string;
  headers: {
    "Content-Type": string;
    Authorization: string;
  };
  body: string;
};

export async function CreateTemplate(
  selection: IObjectWithKey,
  selectedBrand: string,
  templates: TemplateItem[],
  site: string,
  body: string,
  user: string
): Promise<boolean> {
  const listItem = selection as IDetailsListItem;
  const templateId = GetTemplate(listItem.template, selectedBrand, templates);
  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getAccessToken("docAlloyApi")),
    },
    body: body,
  } as RequestOptions;

  let postUrl = "";
  if (templateId !== 0) {
    postUrl = `${config.api.docAlloyApiUrl}${config.functionKeys.generateUrl}&templateid=${templateId}&contentid=${listItem.key}&title=doc&author=${user}`;
  } else {
    // create the file without merging with a templates
    postUrl = `${config.api.docAlloyApiUrl}${config.functionKeys.getFileUrl}&contentid=${listItem.key}&author=${user}`;
  }

  if (site !== null && site !== "") {
    // get destination site list id, is used to re-map quickparts
    try {
      const list = sp.web.lists.getByTitle("Project Documents");
      const listId = await list.get().then((l) => {
        return l.Id;
      });
      postUrl = `${postUrl}&destListId=${listId}`;
    } catch (e) {
      console.log(e);
      appInsights.trackException({
        error: new Error(`Failed at geting list id of site : ${site}`),
        properties: { site },
        severityLevel: SeverityLevel.Error,
      });
    }
  }

  const MAX_ATTEMPTS = 1;
  let attempts = 0;
  const run = async (postUrl: string, requestOptions: any) => {
    const rsp = await fetch(postUrl, requestOptions);
    if (rsp.ok) {
      return rsp;
    } else {
      console.log("Fetch failed");
      console.log(requestOptions);
      console.log(rsp);
      if (attempts < MAX_ATTEMPTS) {
        console.log("retrying...");
        attempts = attempts + 1;
        await run(postUrl, requestOptions);
      }
    }
    return rsp;
  };

  const response = await run(postUrl, requestOptions);

  appInsights.trackEvent({
    name: "Generate Document",
    properties: {
      User: user,
      PostUrl: postUrl,
      Site: site,
      Status: response.status,
      Response: response,
    },
  });

  if (response.status === 200) {
    const blob = await response.blob();
    if (!site) {
      DownloadFile(blob, `${listItem.name}.${listItem.fileType}`);
    } else {
      // Get next id from tracker list
      let nextId = "";
      const trackerItem = await GetTracker(listItem.type);
      let recordId = "";
      const project = site.replace("pwoTest-", "").replace("test-", "");
      let title = "";
      if (trackerItem === null || trackerItem === ({} as TrackerItem)) {
        title = `${listItem.name}`.trim();
      } else {
        nextId = GetNextId(project, trackerItem);
        title = `${nextId} ${listItem.name}`.trim();
        if (trackerItem.Prefix === prefix_RecordId) {
          recordId = nextId;
        }
      }

      try {
        await UploadFile(
          blob,
          title,
          listItem.fileType,
          recordId,
          trackerItem.Id,
          user
        );

        if (nextId.trim() !== "" || nextId !== null) {
          try {
            if (trackerItem.Prefix === prefix_RecordId) {
              await UpdateLastUsedId(nextId, trackerItem.Id);
            }
          } catch (e) {
            appInsights.trackException({
              error: new Error(
                `File was successfully added but failed to update last used Id for tracker item : ${e}`
              ),
              properties: { trackerItem, site },
              severityLevel: SeverityLevel.Error,
            });
            alert(
              `File was successfully added but failed to update last used Id for tracker item ${trackerItem.Title}.`
            );
            throw e;
          }
        }
      } catch (e) {
        appInsights.trackException({
          error: new Error(`Error during upload process : ${e}`),
          severityLevel: SeverityLevel.Error,
        });
        return false;
      }
    }
  } else {
    appInsights.trackException({
      error: new Error(
        `Invalid response when generating/getting file : ${response.statusText}`
      ),
      properties: { user, postUrl, response, listItem, site },
      severityLevel: SeverityLevel.Error,
    });
    alert(
      `Error retrieving file ${listItem.name}.\nStatus ${response.status} : ${response.statusText} `
    );
    return false;
  }
  return true;
}

function GetTemplate(
  templateName: string,
  selectedBrand: string,
  templates: TemplateItem[]
): number {
  const template = templates.filter(
    (template) =>
      template.brand === selectedBrand && template.templateType === templateName
  );

  return template[0]?.id ?? 0;
}

function GetDateString(item: IDetailsListItem | undefined): string {
  let dateString = "";
  if (item?.date) {
    var newDate = new Date(item?.date ?? new Date());
    dateString =
      newDate.getDate().toString().padStart(2, "0") +
      "/" +
      (newDate.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      newDate.getFullYear();
  }
  return dateString;
}

function GetBodyJson(
  dateString: string,
  item: IDetailsListItem | undefined,
  project: IProjectGeneric,
  rfi: RFI[],
  variation: Variation[],
  brand: string
): string {
  let body = "";

  const projectJSON = JSON.stringify(project);
  const rfiJSON = JSON.stringify(rfi);
  const variationJSON = JSON.stringify(variation);

  // outstanding versions
  const outstandingRFI = rfi.filter((r) => r.Status !== "Complete");
  const outstandingVariation = variation.filter(
    (v) => v.Status === "Created" || v.Status === "Awaiting Response"
  );
  const outstandingRFIJSON = JSON.stringify(outstandingRFI);
  const outstandingVariationJSON = JSON.stringify(outstandingVariation);

  // any new objects (e.g. Project) must also be added to the RequestBody class in DocAlloy API before they will be registered
  body = `{ Document :  { "Id" : "${item?.documentId}", "Version": "${item?.version}", "Date": "${dateString}", "Brand": "${brand}"}, Project: ${projectJSON},
  RFI: ${rfiJSON},  Variation: ${variationJSON},  OutstandingRFI: ${outstandingRFIJSON},  OutstandingVariation: ${outstandingVariationJSON}} `;

  return body;
}
