import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  BearerTokenFetchClient,
  IFetchOptions,
  isUrlAbsolute,
} from "@pnp/common";
import { PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import * as auth from "../lib/Auth";
import appInsights from "../ApplicationInsights";

export class PnPFetchClient extends BearerTokenFetchClient {
  constructor(private authContext: PublicClientApplication) {
    super(null);
  }

  public async fetch(
    url: string,
    options: IFetchOptions = {}
  ): Promise<Response> {
    if (!isUrlAbsolute(url)) {
      throw new Error("You must supply absolute urls to PnPFetchClient.fetch.");
    }

    const token = await this.getToken(this.getResource(url));
    this.token = token;
    return super.fetch(url, options);
  }

  private async getToken(resource: string): Promise<string> {
    const request = {} as SilentRequest;

    if (resource.indexOf("sharepoint") !== -1) {
      request.scopes = [
        `${resource}/AllSites.Read`,
        `${resource}/AllSites.Write`,
      ];
    }
    request.forceRefresh = false;

    const account = auth.getAccount();
    if (account) {
      request.account = account;
    }

    try {
      const response = await this.authContext.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error: any) {
      if (this.requiresInteraction(error.errorCode)) {
        this.authContext.acquireTokenRedirect(request);
      } else {
        appInsights.trackException({
          error: new Error(
            `Error aquiring token in pnp fetch client: ${error}`
          ),
          severityLevel: SeverityLevel.Critical,
        });
        throw error;
      }
      return error;
    }
  }

  private requiresInteraction(errorCode: string) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return (
      errorCode === "consent_required" ||
      errorCode === "interaction_required" ||
      errorCode === "login_required"
    );
  }

  private getResource(url: string): string {
    const parser = document.createElement("a") as HTMLAnchorElement;
    parser.href = url;
    return `${parser.protocol}//${parser.hostname}`;
  }
}
