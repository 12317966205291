import { getAccessToken } from "./Auth";
import createGraphClient from "./MsGraphApi";

export type UserProfileInfo = {
  fullName: string;
  initals: string;
  avatarUrl: string | null;
  position: string;
  accountName: string;
};

const getGraphApiClient = async () => {
  const token = await getAccessToken("graphApi");

  if (token) {
    return createGraphClient(token);
  } else {
    throw new Error("Could not retrieve graphAPI access token");
  }
};

export const getProfileInfo = async (): Promise<UserProfileInfo> => {
  const graphClient = await getGraphApiClient();
  const profile = await graphClient.getCurrentUserInfo();
  let profileImageUrl = null;

  const accountName = profile.userPrincipalName.split("@")[0];

  try {
    profileImageUrl =
      (await graphClient.getCurrentUserPhotoDataUrl(96)) ?? null;
  } catch {}

  let initals = "";
  try {
    initals = profile.givenName[0] + " " + profile.surname[0];
  } catch {
    try {
      initals = profile.givenName[0];
    } catch {
      console.log("Initials could not be found for user.");
    }
  }

  return {
    fullName: profile.displayName,
    initals: initals,
    avatarUrl: profileImageUrl,
    position: profile.jobTitle,
    accountName: accountName,
  };
};
