import {
  IDropdownStyles,
  mergeStyles,
  mergeStyleSets,
} from "office-ui-fabric-react";
import { FontSizes } from "@fluentui/theme";

export const headingClass = mergeStyles({
  displayName: "heading",
  alignContent: "center",
  fontSize: FontSizes.large,
});

export const mainClass = mergeStyles({
  displayName: "main",
  //maxWidth: "1300px",
  margin: "auto", //border: '3px solid #73AD21'
});

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "250px", marginRight: "20px" },
};

export const topClass = mergeStyles({
  display: "flex",
  marginBottom: "50px",
});

export const bottomClass = mergeStyles({
  display: "flex",
  marginBottom: "50px",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: "20px",
});

export const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
});

export const titleStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  font: "Segoe UI",
  fontSize: "26px",
};

export const linkStyle: React.CSSProperties = {
  color: "blue",
};
