/* eslint-disable */
import { IProjectGeneric } from "../../../models/Project";
import { useAuth } from "../../../components/AuthProvider";
import { useEffect, useRef, useState } from "react";
import { ProjectApi } from "./ProjectApi";

export function useProject(projectCode: string | null): {
  project: IProjectGeneric;
  isProjectLoading: boolean;
  error: Error | null;
} {
  const isMounted = useRef(false);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [project, setProject] = useState<IProjectGeneric>(
    {} as IProjectGeneric
  );
  const [error, setError] = useState<Error | null>(null);
  const auth = useAuth();

  const init = async () => {
    try {
      const projectJson = require("../../../mockdata/ProjectDetails.json");
      if (projectCode === null || projectCode === "") {
        // if no project code, use placeholder data
        setProject(projectJson as IProjectGeneric);
        setIsProjectLoading(false);
      } else {
        const api = new ProjectApi(auth);
        const response = api
          .getProject(projectCode.replace("UAT-", ""))
          .then((res) => {
            if (!isMounted.current) {
              return;
            }
            const errDesc = res.body as unknown as { Description: string };
            if (
              res.ok === true &&
              errDesc.Description !== "An error occurred"
            ) {
              setProject(res.body);
              setIsProjectLoading(false);
            } else {
              console.log("Invalid project code");
              setProject(projectJson as IProjectGeneric);
              setIsProjectLoading(false);
            }
          });
      }
    } catch (e: any) {
      setProject({} as IProjectGeneric);
      setError(e);
      setIsProjectLoading(false);
    }
  };

  useEffect(function () {
    isMounted.current = true;
    init();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    isProjectLoading,
    project,
    error,
  };
}
