import React from "react";
import { ElevatedViewProps, ElevatedView } from "./index";

export function ElevatedContentView(props: ElevatedViewProps) {
  return (
    <ElevatedView
      depth={8}
      style={{ background: "white", padding: 20 }}
      {...props}
    >
      {props.children}
    </ElevatedView>
  );
}
