/* eslint-disable */
import { useAuth } from "../../../components/AuthProvider";
import { useEffect, useRef, useState } from "react";
import { DocumentApi } from "./DocumentApi";
import { ContentItem, TemplateItem } from "../../../models/ListItems";

type AppData = ContentItem[] & TemplateItem[];

export function useDocumentList(
  searchTerm: string | null,
  folder: string | null
): {
  data: {
    contents: ContentItem[];
    templates: TemplateItem[];
  };
  isDataLoading: boolean;
  dataError: Error | null;
} {
  const isMounted = useRef(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [dataError, setDataError] = useState<Error | null>(null);
  const [data, setData] = useState<{
    contents: ContentItem[];
    templates: TemplateItem[];
  }>({
    contents: [],
    templates: [],
  });

  const auth = useAuth();

  const init = async () => {
    try {
      const api = new DocumentApi(auth);
      const contentResponse = await api.getContentList(searchTerm, folder);
      const templateResponse = await api.getTemplateList();
      if (!isMounted.current) {
        return;
      }
      if (contentResponse.ok && templateResponse.ok) {
        const sortedContents = (contentResponse.body.contents =
          contentResponse.body.contents.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          ));
        setData({
          contents: sortedContents,
          templates: templateResponse.body.templates,
        });
        setIsDataLoading(false);
      } else {
        throw new Error(
          `Document list error: ${
            (contentResponse.statusText, templateResponse.statusText)
          }`
        );
      }
    } catch (e: any) {
      setData({
        contents: [],
        templates: [],
      });
      setDataError(e);
      setIsDataLoading(false);
    }
  };

  useEffect(
    function () {
      isMounted.current = true;
      init();
      return () => {
        isMounted.current = false;
      };
    },
    [searchTerm, folder]
  );

  return {
    data,
    isDataLoading,
    dataError,
  };
}
