import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { loadTheme } from "@fluentui/react";
import { initializeIcons } from "@uifabric/icons";
initializeIcons();

loadTheme({
  palette: {
    themePrimary: "#be0007",
    themeLighterAlt: "#fcf2f3",
    themeLighter: "#f5cecf",
    themeLight: "#eca5a7",
    themeTertiary: "#d9575b",
    themeSecondary: "#c7181e",
    themeDarkAlt: "#ac0006",
    themeDark: "#910005",
    themeDarker: "#6b0004",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

if (window.self === window.top) {
  ReactDOM.render(<App />, document.getElementById("root"));
} else {
  console.log("Not rendering app due to being inside iFrame");
}
