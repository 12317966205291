import { IColumn } from "@fluentui/react/lib/DetailsList";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { IDetailsListItem } from "../../models/ListItems";
import { classNames } from "../Styles";
import React from "react";
import {
  ArrowSync16Regular as LoadIcon,
  Checkmark16Regular as CheckIcon,
  Dismiss16Regular as CrossIcon,
} from "@fluentui/react-icons";

export function renderItemColumn(
  item: IDetailsListItem,
  _index: number | undefined,
  column: IColumn | undefined
): React.ReactNode {
  const fieldContent = item[
    column?.fieldName as keyof IDetailsListItem
  ] as string;
  switch (column?.key) {
    case "colStatus":
      switch (item.status) {
        case "Processing":
          return <LoadIcon primaryFill="blue" />;
        case "Done":
          return <CheckIcon primaryFill="green" />;
        case "Failed":
          return <CrossIcon primaryFill="red" />;
        default:
          return <span></span>;
      }
    default:
      return <div title={fieldContent}> {fieldContent}</div>;
  }
}

export const contentColumns: IColumn[] = [
  {
    key: "colIcon",
    name: "File Type",
    fieldName: "icon",
    minWidth: 16,
    maxWidth: 16,
    isIconOnly: true,
    onRender: (item: IDetailsListItem) => {
      return (
        <TooltipHost content={`${item.fileType} file`}>
          <img
            src={item.icon}
            className={classNames.fileIconImg}
            alt={`${item.fileType} file icon`}
          />
        </TooltipHost>
      );
    },
  },
  {
    key: "colName",
    name: "Title",
    fieldName: "name",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "colFileName",
    name: "File Name",
    fieldName: "fileName",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "colType",
    name: "Type",
    fieldName: "type",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "colTemplate",
    name: "Template",
    fieldName: "template",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "colLocation",
    name: "Location",
    fieldName: "folder",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    onRender: (item: IDetailsListItem) => (
      <TooltipHost content={`${item.folder}`}>{item.folder}</TooltipHost>
    ),
  },
  {
    key: "colDocId",
    name: "Document Id",
    fieldName: "documentId",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    onRender: (item: IDetailsListItem) => (
      <span>{item.documentId === "DocId" ? "" : item.documentId}</span>
    ),
  },
  {
    key: "colVersion",
    name: "Version",
    fieldName: "version",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "colStatus",
    name: "Status",
    fieldName: "status",
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
];
