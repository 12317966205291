import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import { useAuth } from "../AuthProvider";
import { navigate } from "hookrouter";
import { Spinner, SpinnerSize, Stack } from "@fluentui/react";

type SecurePageProps = {
  children: React.ReactNode;
};

export const SecurePage: FunctionComponent<SecurePageProps> = ({
  children,
}) => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isChecking) {
      navigate("/login", true, {
        returnTo: window.location.pathname + window.location.search,
      });
    }
  }, [auth.isAuthenticated, auth.isChecking]);

  if (auth.isChecking) {
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        verticalFill
        style={{ minHeight: 250 }}
      >
        <Spinner size={SpinnerSize.large} label="Authenticating..." />
      </Stack>
    );
  } else if (!auth.isAuthenticated) {
    return null;
  } else {
    return <>{children}</>;
  }
};

export default SecurePage;
