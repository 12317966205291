import React, { ReactNode } from "react";
import {
  Selection,
  SelectionMode,
  IColumn,
  IObjectWithKey,
  IDetailsFooterProps,
  DetailsList,
  DetailsListLayoutMode,
  ConstrainMode,
} from "@fluentui/react/lib/DetailsList";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  TextField,
} from "@fluentui/react";
import { IRenderFunction } from "@fluentui/react/lib/Utilities";
import { dropdownStyles } from "../Styles";
import gridStyles from "./GridStyles";
import { ElevatedContentView } from "../ElevatedView/ElevatedContentView";

interface Props<I> {
  user?: string;
  columns: IColumn[];
  tableName: string;
  items: I[];
  onClick?: (object: IObjectWithKey[]) => void;
  onSearch?: (term: string) => void;
  onBrandChange?: (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined
  ) => void;
  onFolderChange?: (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined
  ) => void;
  folderOptions?: IDropdownOption[];
  brandOptions?: IDropdownOption[];
  defaultSelectedBrandKey: string;
  defaultSelectedFolderKey: string;
  onItemInvoked?: (object: I) => void;
  onRenderItemColumn?: (
    item: any,
    index?: number,
    column?: IColumn
  ) => ReactNode;
  selectionMode: SelectionMode;
  selection: Selection;
  onRenderDetailsFooter?: IRenderFunction<IDetailsFooterProps>;
  disableFilters: boolean;
}

export class GenericDetailsList<TState> extends React.Component<Props<TState>> {
  public render(): JSX.Element {
    return (
      <div>
        <div
          className="search"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginBottom: "15px",
          }}
        >
          <Dropdown
            id="dd_Brand"
            onChange={(ev, option) => {
              this.props.onBrandChange?.(ev, option);
            }}
            onRenderTitle={(options) => (
              <span>Brand: {options ? options[0].text : ""}</span>
            )}
            options={
              this.props.brandOptions
                ? this.props.brandOptions
                : ([
                    {
                      key: "brandHeader",
                      text: "Brand",
                      itemType: DropdownMenuItemType.Header,
                    },
                  ] as IDropdownOption[])
            }
            defaultSelectedKey={this.props.defaultSelectedBrandKey}
            styles={dropdownStyles}
            disabled={this.props.disableFilters}
          />
          <Dropdown
            id="dd_Folder"
            onChange={(ev, option) => {
              this.props.onFolderChange?.(ev, option);
            }}
            onRenderTitle={(options) => (
              <span>Folder: {options ? options[0].text : "Select folder"}</span>
            )}
            disabled={this.props.disableFilters}
            options={
              this.props.folderOptions
                ? this.props.folderOptions
                : ([
                    {
                      key: "folderHeader",
                      text: "Folder",
                      itemType: DropdownMenuItemType.Header,
                    },
                  ] as IDropdownOption[])
            }
            defaultSelectedKey={this.props.defaultSelectedFolderKey}
            styles={dropdownStyles}
          />
          <TextField
            placeholder={"Search " + this.props.tableName}
            id="project_search"
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                this.props.onSearch?.(ev.currentTarget.value ?? "");
              }
            }}
            iconProps={{ iconName: "Search" }}
            styles={{ root: { width: "300px" } }}
            disabled={this.props.disableFilters}
          />
        </div>
        <ElevatedContentView>
          <DetailsList
            constrainMode={ConstrainMode.unconstrained}
            items={this.props.items}
            selectionMode={this.props.selectionMode}
            columns={this.props.columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            onRenderItemColumn={this.props.onRenderItemColumn}
            styles={gridStyles}
            onItemInvoked={this.props.onItemInvoked}
            selection={this.props.selection}
            selectionPreservedOnEmptyClick={true}
            onRenderDetailsFooter={this.props.onRenderDetailsFooter}
          />
        </ElevatedContentView>
      </div>
    );
  }
}

export default GenericDetailsList;
