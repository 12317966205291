export function fileIcon(docType: string): { url: string } {
  // library does not include all doc types
  if (docType === "xlsm" || docType === "xls") {
    docType = "xlsx";
  }

  return {
    url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType}.svg`,
  };
}

export function itemStatus(status: number): string {
  switch (status) {
    case 1:
      return "Processing";
    case 2:
      return "Done";
    case 3:
      return "Failed";
    default:
      return "";
  }
}

export const statusValues = {
  None: 0,
  Processing: 1,
  Successful: 2,
  Unsuccessful: 3,
};
