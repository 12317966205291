import React from "react";
import SecurePage from "../components/SecurePage";

const requiresAuthentication = <P extends object>(
  Component: React.ComponentType<P>
) =>
  class RequiresAuthenticationHOC extends React.Component<P> {
    render() {
      return (
        <SecurePage>
          <Component {...(this.props as P)} />
        </SecurePage>
      );
    }
  };

export default requiresAuthentication;
