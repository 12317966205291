/* eslint-disable */
import config from "../../../Config";
import { RFISearchResult } from "../../../models/RFI";
import { getAccessToken } from "../../Auth";
import { ApiError, ApiOptions, ApiResponse, isApiError } from "../GenericApi";
import { RFI_LIMIT } from "../../../constants/global";

export class RFIApi {
  private baseUrl: string = config.api.webApiUrl;

  constructor() {}

  async getRFIList(
    projectCode: string,
    page: number = 1,
    limit: number = RFI_LIMIT
  ) {
    /**
     * Endpoint variable uses arguments provided in function call to determine project code, limit of RFI's per page, and which page is being to be filled
     * This endpoint on the back-end side uses the page argument to ignore the previous page's RFI's, with each page containing an amount equal to RFI_LIMIT.
     */
    let endpoint = `ProjectRFI/GetProjectRFI?projectCode=${projectCode}&limit=${limit}&page=${page}`;
    return await this.callApi<RFISearchResult>("GET", endpoint);
  }

  private async callApi<TRes>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    endpoint: string,
    params?: Record<string, any>,
    options?: ApiOptions
  ): Promise<ApiResponse<TRes>> {
    const cancelResponse = { isCancelled: true, textStatus: "abort" };

    let cancelled = false;

    if (options?.cancellationToken) {
      options.cancellationToken.cancel = () => (cancelled = true);
    }

    try {
      if (cancelled) return Promise.reject(cancelResponse);

      const accessToken = await getAccessToken("centralApi");

      if (!accessToken) throw new Error("Access token is null");

      let url = this.baseUrl + endpoint;

      if (method === "GET" && params) {
        url += "?" + new URLSearchParams(params);
      }

      if (cancelled) return Promise.reject(cancelResponse);

      const response = await fetch(url, {
        method,
        body: method !== "GET" ? JSON.stringify(params) : undefined,
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          Prefer: "use-new-serialization-format",
        },
      });

      if (cancelled) return Promise.reject(cancelResponse);

      if (response.status === 200) {
        const body = (await response.json()) as TRes | ApiError;

        if (isApiError(body)) {
          return {
            ok: false,
            status: body.Code ?? response.status,
            statusText: body.Description ?? response.statusText,
          };
        }

        return {
          ok: true,
          body: body,
          status: response.status,
          statusText: response.statusText,
        };
      } else {
        return {
          ok: false,
          status: response.status,
          statusText: response.statusText,
        };
      }
    } catch (e: any) {
      return {
        ok: false,
        status: -1,
        statusText: e?.message ?? "Unknown error",
      };
    }
  }
}
