import { IDetailsListStyles } from "@fluentui/react/lib/DetailsList";

const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    marginTop: "10px",
    selectors: {
      "& [role=grid]": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: "70vh",
      },
    },
  },
  headerWrapper: {
    flex: "0 0 auto",
  },
  contentWrapper: {
    flex: "1 1 auto",
    overflowY: "auto",
    overflowX: "hidden",
  },
};

export default gridStyles;
