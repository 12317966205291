export type ApiStatusResponse = {
  Status: string;
  Successful: boolean;
  Failed: boolean;
};

export type ApiStatusResult = {
  Status: string;
  Successful: boolean;
  Failed: boolean;
};

export type CancellationToken = {
  cancel: () => void;
};

export type ApiOptions = {
  showLoader?: boolean;
  cancellationToken?: CancellationToken;
};

export type ApiError = {
  Code: number;
  Description: string;
  Location: string;
  RecordIdentifier: string;
  Type: string;
};

export function isApiError<T>(result: T | ApiError): result is ApiError {
  return "Code" in result && "Type" in result && "Location" in result;
}

export type ApiResponse<T> =
  | {
      ok: false;
      body?: any;
      status: number;
      statusText: string;
    }
  | {
      ok: true;
      body: T;
      status: number;
      statusText: string;
    };
