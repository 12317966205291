/* eslint-disable */
import config from "../../../Config";
import {
  IProjectGeneric,
  IProjectListSearchResult,
} from "../../../models/Project";
import { AuthInfo } from "../../../components/AuthProvider";
import { getAccessToken } from "../../Auth";
import { ApiOptions, ApiResponse } from "../GenericApi";

export class ProjectApi {
  private baseUrl: string = config.api.webApiUrl;

  constructor(auth: AuthInfo) {}

  async getProject(projectCode: string) {
    const endpoint = `project/GetProjectGeneric?projectCode=${projectCode}`;

    return await this.callApi<IProjectGeneric>("GET", endpoint);
  }

  private async callApi<TRes>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    endpoint: string,
    params?: Record<string, any>,
    options?: ApiOptions
  ): Promise<ApiResponse<TRes>> {
    const cancelResponse = { isCancelled: true, textStatus: "abort" };

    let cancelled = false;

    if (options?.cancellationToken) {
      options.cancellationToken.cancel = () => (cancelled = true);
    }

    try {
      if (cancelled) return Promise.reject(cancelResponse);

      const accessToken = await getAccessToken("centralApi");

      if (!accessToken) throw new Error("Access token is null");

      let url = this.baseUrl + endpoint;

      if (method === "GET" && params) {
        url += "?" + new URLSearchParams(params);
      }

      if (cancelled) return Promise.reject(cancelResponse);

      const response = await fetch(url, {
        method,
        body: method !== "GET" ? JSON.stringify(params) : undefined,
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });

      if (cancelled) return Promise.reject(cancelResponse);

      if (response.ok) {
        return {
          ok: true,
          body: (await response.json()) as TRes,
          status: response.status,
          statusText: response.statusText,
        };
      } else {
        return {
          ok: false,
          status: response.status,
          statusText: response.statusText,
        };
      }
    } catch (e: any) {
      return {
        ok: false,
        status: -1,
        statusText: e?.message ?? "Unknown error",
      };
    }
  }
}
