import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "fea7d93f-f32f-4bae-a9cc-d2d18ee7568c",
  },
});
appInsights.loadAppInsights();
const appInsightsUserName = '@User.Identity.Name.Replace("\\", "\\\\")';
appInsights.setAuthenticatedUserContext(
  appInsightsUserName.replace(/[,;=| ]+/g, "_")
);
appInsights.trackPageView();

export default appInsights;
