import { Link } from "@fluentui/react";
import { navigate } from "hookrouter";
import React from "react";
import tv from "../assets/img/statictv.svg";

const iconStyle: React.CSSProperties = {
  maxWidth: "120px",
};

const style: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
};

export default function NotFound() {
  return (
    <div className="NotFound" style={style}>
      <img
        src={tv}
        alt="tv 404 page TV by Flatart from the Noun Project"
        style={iconStyle}
      />
      <div
        style={{
          fontSize: "26px",
          padding: "15px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span>
          <b>404</b> Page Not Found
        </span>
        <span>
          <Link
            onClick={() => {
              navigate("/");
            }}
          >
            Return to home
          </Link>
        </span>
      </div>
    </div>
  );
}
