import React from "react";

export const fontStyle: React.CSSProperties = {
  font: "Segoe UI",
};

export const textStyle: React.CSSProperties = {
  font: "Segoe UI",
  fontSize: "18px",
};

export const subHeadingStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  font: "Segoe UI",
  fontWeight: "normal",
  fontSize: "20px",
};

export const headingStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  font: "Segoe UI",
  fontWeight: "normal",
  fontSize: "24px",
};

export const titleStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  font: "Segoe UI",
  fontSize: "26px",
};

export const linkStyle: React.CSSProperties = {
  color: "blue",
};
