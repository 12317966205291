import { FunctionComponent } from "react";
import { IPersonaSharedProps, Persona } from "@fluentui/react/lib/Persona";
import {
  DefaultButton,
  IButtonStyles,
  IconButton,
} from "@fluentui/react/lib/Button";
import { Stack } from "@fluentui/react";
type NavProps = {
  personaInfo: IPersonaSharedProps;
  openUserInfoPanel: () => void;
  openHelpPanel: () => void;
};

const personaButtonStyle: IButtonStyles = {
  root: {
    width: "48px",
    height: "48px",
    background: "transparent",
    padding: "0px",
    minWidth: "none",
    border: "none",
    transition: "background 0.4s",
    borderRadius: "50%",
  },
  rootHovered: {
    background: "rgba(255, 255, 255, 0.15)",
  },
};

export const iconButtonStyle: IButtonStyles = {
  icon: {
    color: "white",
  },
  root: {
    width: "48px",
    height: "48px",
    transition: "background 0.4s",
    borderRadius: "50%",
  },
  rootHovered: {
    background: "rgba(255, 255, 255, 0.15)",
  },
};

const HeaderNav: FunctionComponent<NavProps> = ({
  personaInfo,
  openUserInfoPanel,
  openHelpPanel,
}) => {
  return (
    <Stack.Item>
      <Stack horizontal>
        <IconButton
          onClick={openHelpPanel}
          iconProps={{ iconName: "Help" }}
          title="Help"
          ariaLabel="Help"
          styles={iconButtonStyle}
        />

        <DefaultButton styles={personaButtonStyle} onClick={openUserInfoPanel}>
          <Persona {...personaInfo} size={12} hidePersonaDetails />
        </DefaultButton>
      </Stack>
    </Stack.Item>
  );
};

export default HeaderNav;
