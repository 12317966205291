import {
  ContentItem,
  IDetailsListItem,
  TemplateItem,
} from "../../../models/ListItems";
import {
  fileIcon,
  itemStatus,
  statusValues,
} from "../../../components/DetailsList/RenderColumns";
import { IDropdownOption } from "@fluentui/react";

const noTemplateText = "No template available for the selected brand.";

export function onBrandChange(
  _: React.FormEvent<HTMLDivElement>,
  option?: IDropdownOption | undefined
): string | null {
  if (option?.text != null) {
    return option?.text;
  }
  return null;
}

export function loadBrandIndex(templates: TemplateItem[]): {
  [id: string]: string[];
} {
  let brandIndex: { [id: string]: string[] } = {};

  // sort alphabetically by brand
  templates = templates.sort((a, b) =>
    a.brand > b.brand ? 1 : b.brand > a.brand ? -1 : 0
  );
  templates.forEach((template) => {
    if (!(template.brand in brandIndex)) {
      brandIndex[template.brand] = [template.templateType];
    } else if (!brandIndex[template.brand].includes(template.templateType)) {
      brandIndex[template.brand].push(template.templateType);
    }
  });

  return brandIndex;
}

export function loadFolderIndex(contents: ContentItem[]): {
  [path: string]: number[];
} {
  let folderIndex: { [path: string]: number[] } = {};

  // sort alphabetically by folder
  contents = contents.sort((a, b) =>
    a.folder > b.folder ? 1 : b.folder > a.folder ? -1 : 0
  );

  contents.forEach((content) => {
    if (!(content.folder in folderIndex)) {
      folderIndex[content.folder] = [content.id];
    } else if (!folderIndex[content.folder].includes(content.id)) {
      folderIndex[content.folder].push(content.id);
    }
  });
  return folderIndex;
}

export function loadAllItems(
  contents: ContentItem[],
  brandIndex: { [id: string]: string[] },
  brand: string
): { allItems: IDetailsListItem[]; typeIndex: string[] } {
  let allItems: IDetailsListItem[] = [];
  let typeIndex: string[] = [];

  contents.forEach((content) => {
    // load type index
    if (!typeIndex.includes(content.documentType)) {
      typeIndex.push(content.documentType);
    }
    // load content list
    allItems.push({
      key: content.id,
      name: content.name,
      fileName: content.fileName,
      type: content.documentType,
      icon: fileIcon(content.fileType).url,
      fileType: content.fileType,
      template: validateTemplate(brandIndex, brand, content.template),
      templateId: content.template,
      status: itemStatus(statusValues.None),
      folder: content.folder,
      documentId: content.documentId,
      version: content.version,
      date: content.date,
    });
  });

  return {
    allItems,
    typeIndex,
  };
}

export function validateTemplate(
  brandIndex: { [id: string]: string[] },
  selectedBrand: string,
  templateType: string
): string {
  if (selectedBrand in brandIndex) {
    if (brandIndex[selectedBrand].includes(templateType)) return templateType;
  }
  return noTemplateText;
}

export function includesText(
  i: IDetailsListItem,
  text: string = noTemplateText
): boolean {
  if (i.template === text && i.templateId !== "") {
    return false;
  }
  return true;
}
