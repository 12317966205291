import { FunctionComponent, useEffect, useState } from "react";
import PageContent from "../components/PageContent/Index";
import { GenericDetailsList } from "../components/DetailsList/Index";
import {
  MarqueeSelection,
  SelectionMode,
  Selection,
  PrimaryButton,
  IObjectWithKey,
  SpinnerSize,
  Spinner,
  DropdownMenuItemType,
} from "@fluentui/react";
import requiresAuthentication from "../helpers/requiresAuthentication";
import { IDetailsListItem } from "../models/ListItems";
import { useDocumentList } from "../lib/APIs/Documents/DocumentProvider";
import {
  contentColumns,
  renderItemColumn,
} from "../components/DetailsList/ContentColumns";
import {
  loadBrandIndex,
  loadFolderIndex,
  onBrandChange,
} from "../lib/APIs/Documents/DocumentListHelpers";
import { useItemList } from "../lib/ItemProvider";
import { useProject } from "../lib/APIs/Project/ProjectProvider";
import { useRFIList } from "../lib/APIs/RFI/RFIProvider";
import { useVariationList } from "../lib/APIs/Variation/VariationProvider";
import { IDropdownOption } from "office-ui-fabric-react";
import React from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import appInsights from "../ApplicationInsights";
import { onCreateClick } from "../lib/TemplateHelper";

let title = "";

const Home: FunctionComponent = () => {
  const [selectedItems, setSelectionItems] = useState<
    IObjectWithKey[] | undefined
  >(undefined);

  let selection = new Selection({
    onSelectionChanged: () => {
      setSelectionItems(selection.getSelection());
    },
  });
  const siteInfo = window.__SITE_INFO__;
  const user = useUserInfo();

  let projectCode = null;

  if (siteInfo) {
    title = "Generating documents for " + siteInfo.site;
    projectCode = siteInfo.site;
  }
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [brand, setBrand] = useState<string>(
    window.__BRAND__ ?? "SAGE Automation"
  );

  const [folder, setFolder] = useState<string>("");
  const [brandIndex, setBrandIndex] = useState<{
    [id: string]: string[];
  }>({});

  // get template/content data
  const { data, isDataLoading, dataError } = useDocumentList(
    searchTerm,
    folder
  );

  const [folderOptions, setFolderOptions] = useState<
    IDropdownOption[] | undefined
  >(undefined);
  const [brandOptions, setBrandOptions] = useState<
    IDropdownOption[] | undefined
  >(undefined);
  // get project data

  const { project, isProjectLoading, error } = useProject(projectCode);
  const { rfiListResult, isRFIListLoading } = useRFIList(projectCode);
  const { variationListResult, isVariationListLoading } =
    useVariationList(projectCode);

  useEffect(() => {
    if (isDataLoading === false) {
      const folderIndex = loadFolderIndex(data.contents);
      const bIndex = loadBrandIndex(data.templates);
      setBrandIndex(bIndex);

      // sort by name
      data.contents = data.contents.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      let fOptions = [
        {
          key: "folderHeader",
          text: "Folder",
          itemType: DropdownMenuItemType.Header,
        },
        {
          key: "", // keep key for All blank
          text: "All",
        },
      ] as IDropdownOption[];

      var headers = [] as string[];
      for (const i in folderIndex) {
        if (i !== "" && i !== null) {
          const parent = i.split("/")[0];
          if (i.split("/").length === 1 || !headers.includes(parent)) {
            if (i.split("/").length === 1) {
              fOptions = addFolderHeader(i, fOptions);
              headers.push(i);
            } // special case for empty parent folder
            else {
              fOptions = addFolderHeader(parent, fOptions);
              fOptions.push({ key: parent, text: parent } as IDropdownOption);
              headers.push(parent);
            }
          }
          fOptions.push({ key: i, text: i } as IDropdownOption);
        }
      }
      let bOptions = [
        {
          key: "brandHeader",
          text: "Brand",
          itemType: DropdownMenuItemType.Header,
        },
      ] as IDropdownOption[];
      for (const i in bIndex) {
        if (i !== "" && i !== null) {
          bOptions.push({ key: i, text: i } as IDropdownOption);
        }
      }
      setFolderOptions(fOptions);
      setBrandOptions(bOptions);

      // if triggered from project workspace site, match folder filter based on site folder location
      if (siteInfo) {
        const siteFolder = siteInfo.pathToFolder.replace(
          siteInfo.rootFolder + "/",
          ""
        );
        let siteFolderArr = siteFolder.split("/");

        // if no match if found, remove lowest subfolder and try again
        while (siteFolderArr.length > 0) {
          let folderMatch = fOptions?.find(
            (f) => f.key === siteFolderArr.join("/") // eslint-disable-line no-loop-func
          );
          if (folderMatch) {
            setFolder(folderMatch.key.toString());
            siteFolderArr = [];
          } else {
            siteFolderArr.pop();
          }
        }
      }
    }
  }, [isDataLoading]); // eslint-disable-line

  React.useEffect(() => {
    if (
      project.DocumentBrand !== null &&
      project.DocumentBrand !== "" &&
      project.DocumentBrand !== undefined
    ) {
      setBrand(project.DocumentBrand);
    }
  }, [project]);

  // convert data in table rows
  const { items } = useItemList(
    data.contents ?? [],
    data.templates ?? [],
    brand,
    brandIndex
  );

  if (
    !isDataLoading &&
    !isProjectLoading &&
    !isRFIListLoading &&
    !isVariationListLoading &&
    !user.isUserInfoLoading
  ) {
    if (dataError) {
      appInsights.trackException({
        exception: dataError,
      });
      appInsights.trackEvent({
        name: "Error retrieving data",
        properties: {
          User: user,
          Error: error,
          Success: false,
        },
      });
      return (
        <PageContent title={title}>
          {dataError.message ??
            error?.message ??
            "An unexpected error has occurred"}
        </PageContent>
      );
    }

    return (
      <PageContent title={title}>
        <MarqueeSelection selection={selection}>
          <GenericDetailsList<IDetailsListItem>
            items={items}
            columns={contentColumns}
            tableName="Documents"
            onSearch={(term) => {
              setSearchTerm(term);
            }}
            onBrandChange={(ev, option) => {
              setBrand(onBrandChange(ev, option) ?? brand);
            }}
            onFolderChange={(ev, option) => {
              setFolder(option?.key.toString() ?? "");
            }}
            folderOptions={folderOptions}
            brandOptions={brandOptions}
            onRenderItemColumn={(item, index, column) => {
              return renderItemColumn(item, index, column);
            }}
            defaultSelectedBrandKey={brand}
            defaultSelectedFolderKey={folder ?? ""}
            selectionMode={SelectionMode.multiple}
            selection={selection}
            disableFilters={isProcessing}
          />
        </MarqueeSelection>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          {siteInfo ? (
            <div>
              Generated documents will be uploaded to:&nbsp;
              <a
                title="Go back to SharePoint site"
                href={"https://gotosage.sharepoint.com" + siteInfo.pathToFolder}
              >{`https://gotosage.sharepoint.com${siteInfo.pathToFolder}`}</a>
            </div>
          ) : (
            ""
          )}
          <PrimaryButton
            className="createBtn"
            styles={{ root: { marginLeft: "20px", width: "200px" } }}
            disabled={
              isProcessing ||
              selectedItems === undefined ||
              selectedItems === ([] as IObjectWithKey[]) ||
              selectedItems.length === 0
                ? true
                : false
            }
            onClick={async () => {
              setIsProcessing(true);
              await onCreateClick(
                selectedItems ?? [],
                brand,
                data.templates,
                items,
                project,
                rfiListResult.results,
                variationListResult.results,
                user.userProfileInfo.fullName
              );
              setIsProcessing(false);
            }}
          >
            {isProcessing
              ? "Creating..."
              : siteInfo
              ? "Create and Upload Documents"
              : "Create Documents"}
          </PrimaryButton>
        </div>
      </PageContent>
    );
  } else {
    return (
      <PageContent title={title}>
        <Spinner
          label="Loading..."
          labelPosition="top"
          size={SpinnerSize.large}
        />
        <GenericDetailsList<IDetailsListItem>
          items={[] as IDetailsListItem[]}
          columns={contentColumns}
          onRenderItemColumn={(item, index, column) => {
            return renderItemColumn(item, index, column);
          }}
          defaultSelectedBrandKey={brand}
          defaultSelectedFolderKey={folder ?? ""}
          selectionMode={SelectionMode.none}
          tableName="Documents"
          selection={selection}
          disableFilters={true}
        />
      </PageContent>
    );
  }
};

function addFolderHeader(
  name: string,
  fOptions: IDropdownOption[]
): IDropdownOption[] {
  // add in a divider
  fOptions.push({
    key: "divider" + name,
    text: " -",
    itemType: DropdownMenuItemType.Divider,
  } as IDropdownOption);
  fOptions.push({
    key: "header" + name,
    text: name,
    itemType: DropdownMenuItemType.Header,
  } as IDropdownOption);
  return fOptions;
}

export default requiresAuthentication(Home);
