import { useCallback, useEffect, useRef, useState } from "react";
import { VariationApi } from "./VariationApi";
import { Variation, VariationSearchResult } from "../../../models/Variation";

export function useVariationList(projectCode: string | null): {
  variationListResult: VariationSearchResult;
  isVariationListLoading: boolean;
  variationError: Error | null;
} {
  const isMounted = useRef(false);
  const [variationListResult, setVariationListResult] =
    useState<VariationSearchResult>({} as VariationSearchResult);
  const [isVariationListLoading, setIsVariationListLoading] = useState(true);
  const [variationError, setError] = useState<Error | null>(null);

  const init = useCallback(async () => {
    try {
      if (projectCode === null || projectCode === "") {
        setVariationListResult({
          results: [] as Variation[],
        } as VariationSearchResult);
        setIsVariationListLoading(false);
      } else {
        const api = new VariationApi();
        const response = await api.getVariationList(projectCode);
        if (!isMounted.current) {
          return;
        }
        if (response.ok === true) {
          setVariationListResult(response.body);
          setIsVariationListLoading(false);
        } else {
          console.log(response);

          throw new Error(`Variation list error: ${response.statusText}`);
        }
      }
    } catch (e: any) {
      setVariationListResult({
        results: [] as Variation[],
      } as VariationSearchResult);
      setError(e);
      setIsVariationListLoading(false);
    }
  }, [projectCode]);

  useEffect(() => {
    isMounted.current = true;
    init();
    return () => {
      isMounted.current = false;
    };
  }, [init]);

  return {
    variationListResult,
    isVariationListLoading,
    variationError,
  };
}
