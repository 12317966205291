import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { sp } from "@pnp/sp/presets/all";
import appInsights from "../ApplicationInsights";
import { SiteInfo } from "../models/SiteInfo";
import "@pnp/sp/fields";
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";
import { getDisplayDate, getDisplayTime } from "../helpers/getDisplayDate";

export function DownloadFile(blob: Blob, fileName: string): void {
  appInsights.trackEvent({
    name: "Download file",
    properties: { fileName },
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export async function UploadFile(
  blob: Blob,
  fileName: string,
  fileType: string,
  recordId: string,
  docType: number,
  user: string
): Promise<void> {
  const siteInfo = window.__SITE_INFO__ as SiteInfo;
  const site = siteInfo?.site ?? "";
  appInsights.trackEvent({
    name: "Upload file to site",
    properties: { site, fileName },
  });

  try {
    try {
      const folder = sp.web.getFolderByServerRelativeUrl(
        siteInfo?.pathToFolder ?? ""
      );
      let response = null;
      try {
        response = await folder.files.add(
          `${fileName}.${fileType}`,
          blob,
          false
        );
      } catch (e: any) {
        // try again
        try {
          response = await folder.files.add(
            `${fileName} ${getDisplayDate(new Date())}.${fileType}`,
            blob,
            false
          );
        } catch (e: any) {
          response = await folder.files.add(
            `${fileName} ${getDisplayTime(new Date())}_${getDisplayDate(
              new Date()
            )}.${fileType}`,
            blob,
            false
          );
        }
      }

      appInsights.trackEvent({
        name: "Upload file to site",
        properties: {
          User: user,
          Document: `${fileName}.${fileType}`,
          Site: site,
          Success: response != null ? true : false,
          Data: response != null ? response.data : "Failed to upload",
        },
      });

      // update list item
      try {
        const item = await response.file.getItem();
        await item.update({
          RecordId: recordId,
          DocumentTypeId: docType,
          Revision: 1,
        });
      } catch (e) {
        appInsights.trackException({
          error: new Error(`List item fields not updated for ${site} : ${e}.`),
          severityLevel: SeverityLevel.Information,
        });
      }
    } catch (e) {
      appInsights.trackEvent({
        name: "Upload file to site",
        properties: {
          User: user,
          Document: `${fileName}.${fileType}`,
          Site: site,
          Success: false,
          Error: e,
        },
      });

      appInsights.trackException({
        error: new Error(`Server by relative url failed for ${site} : ${e}.`),
        severityLevel: SeverityLevel.Information,
      });
      throw e;
    }
  } catch (e) {
    appInsights.trackException({
      error: new Error(`Error uploading file : ${e}.`),
      severityLevel: SeverityLevel.Error,
    });
    alert(`Error uploading file:  ${fileName} \n ${e}`);
  }
}
